@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.question-section {
  // padding: 10px;
  font-family: "Montserrat";

  &__text {
    font-size: 23px;
    line-height: 27px;
    color: #000000;
    font-weight: 700 !important;

    @media (max-width: 1023.99px) {
      font-size: 20px;

      line-height: 20px;
    }

    @media (max-width: 767.99px) {
      font-size: 18px;

      line-height: 18px;
    }

    @media (max-width: 567.99px) {
      font-size: 16px;

      line-height: 16px;
    }
  }

  &__desc {
    &__paragraph {
      color: #5a5a5a !important;
      font-size: 10px !important;
    }
  }

  // &__input-fields {
  //     width: 210px;
  //     height: 47px;
  //     padding: 22px;
  //     border-radius: 10px;
  //     background-color: #ebe9e9;

  //     &::placeholder {
  //         font-size: 18px;
  //         line-height: 48px;
  //         color: #bab9b9;
  //         font-weight: 400;
  //         font-family: "Montserrat";
  //     }
  // }

  &__num {
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    font-weight: 400;

    margin-bottom: 0.3rem !important;

    @media (max-width: 1023.99px) {
      font-size: 15px;

      line-height: 24px;

      margin-bottom: 0.4rem !important;
    }

    @media (max-width: 767.99px) {
      font-size: 14px;

      line-height: 22px;

      margin-bottom: 0.3rem !important;
    }

    @media (max-width: 567.99px) {
      font-size: 12px;

      line-height: 20px;

      margin-bottom: 0.3rem !important;
    }
  }

  &__radiobtn {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: #ebe9e9 !important;

    @media (max-width: 1023.99px) {
      width: 17px;

      height: 17px;

      margin-bottom: 0.4rem !important;
    }

    @media (max-width: 767.99px) {
      width: 15px;

      height: 15px;
      // border-radius: 6px;
      margin-top: 0.1rem !important;

      margin-left: -1rem !important;
    }

    @media (max-width: 567.99px) {
      width: 14px;

      height: 14px;

      border-radius: 6px;

      margin-top: 0.1rem !important;

      margin-left: -1rem !important;
    }
  }

  &__opttext {
    font-size: 18px;
    // line-height: 48px;
    color: #000000;
    font-weight: 400;
    font-family: "Montserrat";

    @media (max-width: 767.99px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 567.99px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__des {
    font-size: 10px;
    // line-height: 20px;
    color: #5a5a5a !important;
    font-weight: 500;
    font-family: "Montserrat";

    @media (max-width: 567.99px) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__bottom-img img {
    width: 26px !important;

    height: 46px !important;

    @media (max-width: 567.99px) {
      width: 12px !important;
      height: 30px !important;
    }
  }
  &__desc-bottom {
    width: 26px !important;

    height: 40px !important;
  }

  &__loader-content {
    font-size: 28px;
    line-height: 35px;
    color: #000000;
    font-weight: 700;
    text-align: left;
    @media (max-width: 767.99px) {
      font-size: 20px;
      line-height: 32px;
    }
    @media (max-width: 567.99px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__loader-spinner {
    height: 5rem !important;
    width: 5rem !important;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.loaderSection {
  &__spinner {
    width: 5rem !important;
    height: 5rem !important;
    color: #ff6000;
    font-weight: bolder !important;
  }
}

.question-section {
  // padding: 10px;
  font-family: "Montserrat";

  &__desc {
    &__paragraph-tarief {
      color: #5a5a5a !important;
      font-size: 12px !important;

      @media (max-width: 575px) {
        font-size: 10px !important;
      }
    }
  }
}

.footer-text {
  font-size: 10px !important;
}
