.custom-modal {
  border: none !important;
  .arrow {
    transform: scaleX(-1);
    width: 30px;
    cursor: pointer;

    @media (max-width: 767.99px) {
      width: 20px;
    }
  }
  .modal-content {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 45px 45px !important;
    border-radius: none !important;
  }
  .error-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }

  .form-control {
    border-radius: 10px;
    background-color: #ebe9e9;
    font-size: 18px;
    // line-height: 48px;
    color: #000000;
    font-weight: 400;
    font-family: "Montserrat";
    border: none !important;
  }

  .form-control::placeholder {
    color: #bab9b9 !important;
  }

  &__hr {
    height: 1px;
    background-color: #d6d4d4;
    width: 100% !important;
  }

  &__title {
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    font-weight: 700;
    font-family: "Montserrat";
  }

  &__description {
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    font-weight: 400;
    font-family: "Montserrat";
  }
  &__form-description {
    font-size: 22px;
    line-height: 35px;
    color: #000000;
    font-weight: 400;
    font-family: "Montserrat";
  }

  @media (max-width: 991.99px) {
    .modal-content {
      padding: 32px 35px !important;
    }

    &__title {
      font-size: 22px;
      line-height: 27px;
    }

    &__description {
      font-size: 14px;
      line-height: 22px;
    }

    &__form-description {
      font-size: 18px;
      line-height: 25px;
    }

    .form-control {
      font-size: 16px;
      // line-height: 40px;
    }
  }
  @media (max-width: 767.99px) {
    .modal-content {
      padding: 25px 25px !important;
    }
    &__title {
      font-size: 20px;
      line-height: 22px;
    }

    &__description {
      font-size: 13px;
      line-height: 18px;
    }
    &__form-description {
      font-size: 16px;
      line-height: 22px;
    }
    .form-control {
      font-size: 15px;
      // line-height: 30px;
    }
  }

  @media (max-width: 567.99px) {
    .modal-content {
      padding: 22px 15px !important;
    }
    &__title {
      font-size: 18px;
      line-height: 18px;
    }

    &__description {
      font-size: 10px;
      line-height: 16px;
    }

    &__form-description {
      font-size: 14px;
      line-height: 18px;
    }
    .form-control {
      font-size: 13px;
      // line-height: 20px;
    }
  }
}
