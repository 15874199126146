.header {
  height: 600px !important;
  background-image: url("../img/header-bg.png");
  background-size: 100% 100% !important;
  .spacer {
    display: block;
    margin-top: -40px;
    visibility: hidden;
  }

  &__title {
    font-size: 65px;
    line-height: 78px;
    // text-transform: uppercase;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat";
    text-align: end;
  }

  &__sub-title {
    font-size: 31px;
    line-height: 40px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Montserrat";
    text-align: center;

    // margin-right: -400px;
  }

  @media (max-width: 979.99px) {
    img {
      //   width: 120px !important;
      //   height: 194px !important;
      width: 16% !important;
    }
    &__title {
      font-size: 45px;
      line-height: 68px;
    }

    &__sub-title {
      font-size: 20px;
      line-height: 30px;

      // margin-right: -177px;
    }
  }

  @media (max-width: 916.99px) {
    img {
      //   width: 110px !important;
      //   height: 184px !important;
      width: 14% !important;
    }
    // &__title {
    //   font-size: 55px;
    //   line-height: 60px;
    // }

    &__sub-title {
      font-size: 18px;
      line-height: 22px;

      // margin-right: -170px;
    }
  }

  @media (max-width: 856.99px) {
    img {
      //   width: 100px !important;
      //   height: 174px !important;
      width: 13% !important;
    }

    &__title {
      font-size: 40px;
      line-height: 55px;
    }
    .spacer {
      margin-top: -35px;
    }

    &__sub-title {
      font-size: 16px;
      line-height: 18px;

      // margin-right: -125px;
    }
  }

  @media (max-width: 767.99px) {
    height: 420px !important;
    .spacer {
      margin-top: -25px;
    }

    img {
      //   width: 90px !important;
      //   height: 164px !important;
      width: 12% !important;
    }
    &__title {
      font-size: 35px;
      line-height: 45px;
    }

    &__sub-title {
      font-size: 15px;
      line-height: 16px;

      // margin-right: -103px;
    }
  }

  @media (max-width: 583.99px) {
    height: 350px !important;
    .spacer {
      margin-top: -20px;
    }

    img {
      //   width: 75px !important;
      //   height: 130px !important;
      width: 12% !important;
    }
    &__title {
      font-size: 27px;
      line-height: 35px;
    }

    &__sub-title {
      font-size: 14px;
      line-height: 14px;

      // margin-right: -41px;
    }
  }

  @media (max-width: 399.99px) {
    height: 250px !important;
    .spacer {
      margin-top: -15px;
    }

    img {
      //   width: 65px !important;
      //   height: 110px !important;
      width: 10% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px;
    }

    &__sub-title {
      font-size: 10px;
      line-height: 13px;

      // margin-right: -18px;
    }
  }
}
.five-tips-header {
  height: 300px !important;
  background-image: url("../img/bg.jpg");
  // background-image: url("../img/BK_Huis_higher.jpg");
  background-size: 100% 100% !important;
  position: relative !important;
  z-index: -1;

  img {
    width: 40% !important;

    margin-top: -9%;
  }

  @media (max-width: 767.99px) {
    height: 200px !important;
    img {
      width: 45% !important;
      margin-top: -12%;
    }
  }

  @media (max-width: 575.99px) {
    height: 150px !important;
  }
}
.five-tips-header-contract {
  height: 500px !important;
  background-image: url("../img/house.png");
  background-size: 100% 100% !important;
  position: relative !important;
  background-position: center;
  z-index: -1;
  img {
    width: 100% !important;
    height: 800px !important;
    background-size: cover !important;
    position: relative !important;
    z-index: -1;
    // margin-top: -9%;
    @media (min-width: 1920px) {
      height: 800px !important;
    }
  }
  @media (max-width: 768.99px) {
    height: 200px !important;
    img {
      width: 100% !important;
      height: auto !important;
      // margin-top: -12%;
      object-fit: contain !important;
    }
  }
  @media (max-width: 575.99px) {
    height: 150px !important;
  }
}

.paragraph {
  position: relative;
  &__arrowDown {
    position: absolute;
    right: 14%;
    top: 47%;
    height: 50px;
    width: 50px;
    @media (max-width: 992px) {
      right: 14%;
      top: 45%;
      height: 50px;
      width: 50px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
